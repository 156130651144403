import React, { Component } from 'react'
import { Tabs, Tab } from 'react-bootstrap';
import { request } from "../../services/request";
import DataTable from 'react-data-table-component';
import moment from "moment";
import SweetAlert2 from 'react-sweetalert2';

export class SettingsPanel extends Component {

  constructor(props) {
    super(props);
    this.state = { 
        vacationsAll: [],
        swalProps: {}
    }

    this.loadData = this.loadData.bind(this);
    this.preload = this.preload.bind(this);
    this.returnGroupedVacations = this.returnGroupedVacations.bind(this);
}

closeRightSidebar() {
  document.querySelector('.right-sidebar').classList.remove('open');
}

componentDidMount(){
  this.loadData();
}

aproveVacation(id, free_type) {

  let newData = {
    vac_id: id,
    free_type: free_type
  }

  if (!id && !free_type) {

    const data = this.returnGroupedVacations();
    let groupId = [];

    for (let i = 0; i < data.length; i++) {
      if (groupId.indexOf(data[i].group_id) == -1) groupId.push(data[i].group_id);
    } 

    newData = {
      vac_id: -1,
      free_type: -1,
      groupId: groupId
    }
  }

  request({
    method: 'post',
    url: `vacations/aprove`,
    data: newData
  }).then((resp) => {
    this.setState({
      swalProps: {
        show: true,
        title: 'Vacation day aproved',
        showConfirmButton: false,
        timer: 1000,
        icon: 'success',
    }
    })
  })
}

aproveOvertime(id, status) {
  let newData = {
    overtime_id: id,
    overtime_status: status
  }

  let text = status == 1 ? "Overtime aproved" : "Overtime declined";

  request({
    method: 'post',
    url: `vacations/overtimeaprove`,
    data: newData
  }).then((resp) => {
    this.setState({
      swalProps: {
        show: true,
        title: text,
        showConfirmButton: false,
        timer: 1000,
        icon: 'success',
    }
    })
  })
}

onVacationModalClode() {
  this.setState({
    swalProps: {}
  }, this.preload)
}

returnGroupedVacations() {
  if (!this.state.vacationsAll) return [];

  let newData = JSON.parse(JSON.stringify(this.state.vacationsAll));

  newData.sort(function (a, b) {
      return Math.abs(Date.now() - new Date(a.free_date)) - Math.abs(Date.now() - new Date(b.free_date));
  })

  return newData.filter((item) => {
    if (moment(item.free_date).isAfter(moment())) {
      return true
    } else {
      return false;
    }
  })
}

preload() {
  this.props.preload();
  this.loadData();
}

loadData() {
  // Loading all Vacations
  request({
    method: 'get',
    url: `vacations/notifications`,
  }).then((resp) => {
    this.setState({vacationsAll: resp.data});
  })
}

  render() {

    console.log("overtime", this.props.notificationsOvertime);

    const columns = [
        {
            name: 'Full name',
            selector: row => row.user_fullname,
        },
        {
            name: 'Type',
            cell: row => <label className={`badge badge-${row.free_type}`}>{vacationsLabels[row.free_type]}</label>,
             width: "140px"
        },
        {
            name: 'Full / Half',
            cell: row => <label className={row.typeclass ? "" : "badge badge-gradient-dark"}>{row.free_amount} {row.free_halfday_flag == "full" ? " full day" : row.free_halfday_flag == "am" ? " in the morning" : " afternoon"}</label>,
            width: "140px"
        },
        {
            name: 'Date',
            cell: row => {return moment(row.free_date).format("YYYY-MM-DD")},
             width: "130px"
        },
        
        {
            name: 'Message',
            selector: row => row.free_reason,
        },
        {
            name: 'Aprove',
            selector: row => { if (moment(row.free_date).isAfter(moment()) && !row.notified) return <button className="btn btn-info btn-xs mr-2" onClick={() => this.aproveVacation(row.free_id, row.free_type)}>I'm informed</button>},
             width: "140px"
        }
    ];

    return (
      <div>
        <div id="right-sidebar" className="settings-panel right-sidebar">
          <i className="settings-close mdi mdi-close"  onClick={this.closeRightSidebar}></i>
          <Tabs defaultActiveKey="NOTIFICATIONS" className="bg-gradient-primary" id="uncontrolled-tab-example">
            <Tab eventKey="NOTIFICATIONS" title="NOTIFICATIONS" className="test-tab">
              <div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="px-3">
                      <div>
                        <div className="list-wrapper">
                           
                              {this.returnGroupedVacations()[0] ? (
                                 <div className="table-responsive">
                     
                                  <DataTable
                                    columns={columns}
                                    data={this.returnGroupedVacations()}
                                    conditionalRowStyles={conditionalRowStyles}
                                  />
                                  <hr />
                                  <button className="btn btn-info btn-xs mr-2 ml-end" onClick={() => this.aproveVacation()}>Aprove all vacations</button>
                          
                                    </div>
                              ) : null}

                              {this.props.notificationsOvertime[0] ? (
                                <>
                                You have overtime assigned:<br/>
                                <div className="table-responsive mt-4">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Overtime date</th>
                                      <th>Overtime hours</th>
                                      <th>Marked by</th>
                                      <th>Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.props.notificationsOvertime?.map((item, i) => {
                                        return (
                                            <tr>
                                              <td>{moment(item.overtime_date).format("YYYY-MM-DD")}</td>
                                              <td>{item.overtime}</td>
                                              <td>{item.marked_by}</td>
                                              <td>
                                                <button className="btn btn-info btn-xs mr-2" onClick={() => this.aproveOvertime(item.id, 1)}>Aprove</button>
                                                <button className="btn btn-danger btn-xs mr-2" onClick={() => this.aproveOvertime(item.id, 2)}>Decline</button>
                                              </td>
                                            </tr>
                                        );
                                    })}
                                  </tbody>
                                </table>
                                </div>
                                </>
                              ) : null}

                              <SweetAlert2 {...this.state.swalProps} 
                              didClose={() => this.onVacationModalClode()}/>
                              
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    )
  }
}

const vacationsLabels = {
  1: "Vacation",
  2: "Illness",
  3: "Parenthood",
  4: "1 from 5",
  5: "Paternity",
  6: "Business trip",
  7: "Unpaid vacation",
  8: "Learning holidays",
  9: "Working from home",
  11: "Military days"
};

const conditionalRowStyles = [
  {
    when: row => moment(row.free_date).isBefore(moment()),
    style: {
      opacity: 0.5,
    },
  },
];

export default SettingsPanel
